<template>
  <div id="data-list-list-view" class="data-list-container">

    <div v-if="initProgress" class="w-100 mt-5 mb-5">
      <vs-progress indeterminate color="primary"></vs-progress>
    </div>

    <vs-prompt title="Exportar a Excel" class="export-options" @cancle="clearFields" @accept="exportToExcel"
               accept-text="Exportar" cancel-text="Cancelar" @close="clearFields" :active.sync="activePrompt">
      <vs-input v-model="fileName" placeholder="Ingresa el nombre del archivo..." class="w-full"/>
      <v-select v-model="selectedFormat" :options="formats" class="my-4"/>
      <div class="flex">
        <span class="mr-4">Auto ajustar columna:</span>
        <vs-switch v-model="cellAutoWidth">Auto ajustar columna</vs-switch>
      </div>
    </vs-prompt>

    <vx-card collapse="false" ref="filterCard" title="Filtros" class="user-list-filters mb-8" collapse-action
             refresh-content-action @refresh="resetFilters">
      <!--Filter by date-->
      <div>
        <h6 class="mt-2">Rango de fechas</h6>
        <!--Dates-->
        <div class="vx-row mt-4">
          <div class="vx-col md:w-1/2">
            <label class="text-sm opacity-75">Desde*</label>
            <datepicker :language="es" label="Desde" v-model="startDate" id="startDate"
                        v-validate="'required'" name="startDate"></datepicker>
          </div>
          <div class="vx-col md:w-1/2">
            <label class="text-sm opacity-75">Hasta*</label>
            <datepicker :language="es" label="Gasta" v-model="endDate" id="endDate"
                        v-validate="'required'" name="startDate"></datepicker>
          </div>
        </div>
        <!--Clients-->
        <div>
          <div class="vx-row">
            <div class="vx-col md:w-1/2">
              <h6 class="mt-5 mb-2">Selecciona cliente</h6>
              <v-select :options="clients" :clearable="true"
                        :dir="$vs.rtl ? 'rtl' : 'ltr'"
                        v-model="client" class="mb-4 md:mb-0" name="client" id="client"/>
            </div>
            <div class="vx-col md:w-1/2">
              <h6 class="mt-5 mb-2">Orden de compra</h6>
              <vs-input class="w-full" v-model="purchaseOrderFilter"></vs-input>
            </div>
          </div>
        </div>
      </div>


      <div class="vx-col mt-2">
        <vs-button @click="filter" class="mr-6 vs-align-right">Filtrar</vs-button>
      </div>
    </vx-card>

    <new :vehicles="vehicles" :quotes="quotes" :providers="providers" @add="addDataSideBar"
         :isSidebarActive="showNewSidebar"
         @closeSidebar="showNewSidebar= false"/>

    <movements :data="sidebarData" :isSidebarActive="showMovements"
               @closeSidebar="showMovements= false"/>

    <new-w-c :vehicles="vehicles" :clients="clients" :products="products" @add="addDataSideBar"
             :isSidebarActive="showNewSidebarWC"
             @closeSidebar="showNewSidebarWC= false"/>

    <detail
        @update="updateDateSideBar"
        :data="sidebarData"
        :isSidebarActive="showDetail"
        @closeSidebar="showDetail= false"/>

    <vs-table v-model="selected" ref="table" pagination :max-items="itemsPerPage" search
              :data="list">
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

        <div class="flex flex-wrap-reverse items-center data-list-btn-container">
          <!-- Action - dropnow -->
          <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer mr-4">

            <div
                class="p-4 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32 w-full">
              <span class="mr-2">Acciones</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item @click="activePrompt=true">
                            <span class="flex items-center">
                              <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Exportar (Formato para Excel)</span>
                            </span>
              </vs-dropdown-item>
              <vs-dropdown-item
                  v-if="selected.id"
                  @click="selected.nubefact ? openLink(selected.nubefact.enlace_del_pdf): ''">
                            <span class="flex items-center">
                              <feather-icon
                                  icon="ExternalLinkIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Ver documento (O)</span>
                            </span>
              </vs-dropdown-item>
              <vs-dropdown-item
                  v-if="selected.status === 'send'"
                  @click="openPrintDialog(selected)">
                            <span class="flex items-center">
                              <feather-icon
                                  icon="PrinterIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Imprimir (I)</span>
                            </span>
              </vs-dropdown-item>
              <vs-dropdown-item
                  v-if="selected.status === 'hold'"
                  @click="openResendDialog(selected)">
                            <span class="flex items-center">
                              <feather-icon
                                  icon="UploadIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Reenviar (R)</span>
                            </span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
          <!-- Add new -->
          <div
              class="btn-add-new p-3 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary"
              @click="showNewSidebar= true">
            <feather-icon icon="PlusIcon" svgClasses="h-4 w-4"/>
            <span class="ml-2 text-base text-primary">Agregar guía regular</span>
          </div>
          <!-- Add new -->
          <div
              class="btn-add-new p-3 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary"
              @click="showNewSidebarWC= true">
            <feather-icon icon="PlusIcon" svgClasses="h-4 w-4"/>
            <span class="ml-2 text-base text-primary">Agregar guía sin cotización</span>
          </div>
        </div>

        <!-- Items per page -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
          <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{
                currentPage * itemsPerPage - (itemsPerPage - 1)
              }} - {{ list.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : list.length }} de {{
                queriedItems
              }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
          </div>
          <vs-dropdown-menu>

            <vs-dropdown-item @click="itemsPerPage=10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=20">
              <span>20</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=30">
              <span>30</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=40">
              <span>40</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="client">Cliente</vs-th>
        <vs-th sort-key="sequence">Nro. Guía</vs-th>
        <vs-th sort-key="status">Estado</vs-th>
        <vs-th sort-key="registrationDate">Fecha de registro</vs-th>
        <vs-th sort-key="cot">Cot.</vs-th>
        <vs-th sort-key="user">Usuario</vs-th>
        <vs-th sort-key="actions">Acciones</vs-th>
      </template>

      <template slot-scope="{data}">
        <tbody>
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

          <vs-td>
            <p class="font-medium truncate product-name">{{ tr.client.businessName }}</p>
          </vs-td>

          <vs-td>
            <p class="font-medium truncate">{{ tr.code }}</p>
          </vs-td>

          <vs-td>
            <vs-chip :color="getStatusColor(tr.status)" class="product-order-status">
              {{ getStateText(tr.status) | title }}
            </vs-chip>
          </vs-td>

          <vs-td>
            <p class="font-medium truncate">{{ tr.createdAt | date(true) }}</p>
          </vs-td>

          <vs-td>
            <template v-if="tr.quote">
              <vs-chip @click.native="openLink(tr.quote.link)" color="dark" class="product-order-status">
                {{ tr.quote.code }}
              </vs-chip>
            </template>
            <template v-else>
              <p>NA</p>
            </template>
          </vs-td>

          <vs-td>
            <p class="font-medium truncate">{{ tr.user.displayName }}</p>
          </vs-td>

          <vs-td class="whitespace-no-wrap">

            <feather-icon v-if="!tr.accepted" @click="verifyGuide(tr)" title="Verificar"
                          icon="DownloadIcon"
                          svgClasses="w-5 h-5 mr-5 hover:text-primary stroke-current"/>

            <feather-icon @click="openLink(tr.nubefact.enlace_del_pdf)" v-if="tr.status !== 'hold'" title="Link"
                          icon="ExternalLinkIcon"
                          class="mr-3"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current"/>

            <feather-icon @click="openCancelDialog(tr)" v-if="tr.status === 'send'" title="Anular"
                          icon="XIcon"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current mr-3"/>

            <feather-icon v-if="tr.quote" @click="showMovements = true, sidebarData = tr"
                          title="Movimientos"
                          icon="EyeIcon"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current mr-3"/>

            <feather-icon @click="openPrintDialog(tr)" v-if="tr.status === 'send'" title="Imprimir"
                          icon="PrinterIcon"
                          class="mr-3"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current"/>

            <feather-icon @click="openResendDialog(tr)" v-if="tr.status === 'hold'" title="Reenviar"
                          icon="UploadIcon"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current mr-3"/>

            <feather-icon @click="showDetail = true; sidebarData= tr" title="Ver detalle"
                          icon="BoxIcon"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current"/>
          </vs-td>
        </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker'
import New from '@/views/guides/New'
import NewWC from '@/views/guides/NewWC'
import Detail from '@/views/guides/Detail'
import openLink from '@/mixins/link'
import Movements from './QuoteMovements'

import { auth, db, FieldValue, Timestamp } from '@/firebase/firebaseConfig'
import { es } from 'vuejs-datepicker/dist/locale'
import axios from 'axios'
import _ from 'lodash'

const algoliasearch = require('algoliasearch')

const client = algoliasearch('XQUSDVRT6D', 'f5a3a884ae2750149d9d5e052e447506')
const index = client.initIndex('products')

export default {
  name: 'GuidesList',
  components: {
    vSelect,
    New,
    Datepicker,
    NewWC,
    Detail,
    Movements
  },
  data () {
    return {
      selected: {},
      itemsPerPage: 10,
      isMounted: false,
      list: [],
      providers: [],
      quotes: [],
      vehicles: [],
      clients: [],
      products: [],
      initProgress: false,
      selectedGuide: null,
      purchaseOrderFilter: '',

      // Data Sidebar
      showUpdateSidebar: false,
      showNewSidebar: false,
      showNewSidebarWC: false,
      showDetail: false,
      sidebarData: {},
      // Export
      fileName: '',
      formats: ['xlsx', 'csv', 'txt'],
      cellAutoWidth: true,
      selectedFormat: 'xlsx',
      activePrompt: false,
      // Filter
      startDate: null,
      endDate: null,
      es: es,
      client: null,
      evt: null,
      showMovements: false
    }
  },
  mixins: [openLink],
  computed: {
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.list.length
    }
  },
  async created () {
    try {
      this.initProgress = true
      await this.loadGuides()
      await this.loadProviders()
      await this.loadQuotes()
      await this.loadVehicles()
      await this.loadClients()
      await this.loadProducts()
      // Set filter las 30 days
      this.startDate = new Date(new Date().setDate(new Date().getDate() - 30))
      this.endDate = new Date()
    } catch (e) {
      console.log(e)
      this.$vs.notify({
        color: 'warning',
        title: '¡Oh no!',
        text: 'Hubo un error.'
      })
    } finally {
      this.initProgress = false
    }
  },
  mounted () {
    this.isMounted = true

    // xkeyboard
    // this.evt = window.document
    // this.evt.addEventListener('keyup', this.listenKeyboard)
  },
  destroyed () {
    this.evt.removeEventListener('keyup', this.listenKeyboard)
  },
  methods: {
    exportToExcel () {
      import('@/vendor/Export2Excel').then(excel => {
        const _ = require('lodash')
        const list = _.cloneDeep(this.list)
        list.forEach((o) => {
          o.client = o.client.businessName
          o.createdAt = this.$options.filters.date(o.createdAt, true)
          o.user = o.user.displayName
          o.state = this.getStateText(o.status)
          o.quote = o.quote ? o.quote.code : 'NA'
        })
        const headerVal = ['client', 'code', 'createdAt', 'user', 'state', 'quote']
        const headerTitle = ['Cliente', 'Nro. de Guía', 'Fecha de registro', 'Usuario', 'Estado', 'Cotización']
        const data = this.formatJson(headerVal, list)
        excel.export_json_to_excel({
          header: headerTitle,
          data,
          filename: this.fileName,
          autoWidth: this.cellAutoWidth,
          bookType: this.selectedFormat
        })
        this.clearFields()
      })
    },
    clearFields () {
      this.filename = ''
      this.cellAutoWidth = true
      this.selectedFormat = 'xlsx'
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        // Add col name which needs to be translated
        // if (j === 'timestamp') {
        //   return parseTime(v[j])
        // } else {
        //   return v[j]
        // }

        return v[j]
      }))
    },
    /**
     * Add data of sidebar
     * */
    addDataSideBar (o) {
      this.list.unshift(o)
    },
    /**
     * Update data of sidebar
     * */
    updateDateSideBar (o) {
      const index = this.list.findIndex((d) => d.id === o.id)
      Object.assign(this.list[index], o)
    },
    /**
     * Get status color
     * @param status
     * @returns {string}
     */
    getStatusColor (status) {
      if (status === 'hold') return 'warning'
      if (status === 'send') return 'success'
      if (status === 'rejected') return 'danger'
      return 'success'
    },
    /**
     * Get state text
     * @param value
     * @returns {string}
     */
    getStateText (value) {
      if (value === 'hold') return 'Generada'
      if (value === 'send') return 'Enviada'
      if (value === 'rejected') return 'Anulada'
    },
    /**
     *
     * @returns {Promise<void>}
     */
    async loadGuides () {
      let querySnap = {}
      if (this.$acl.check('admin')) {
        querySnap = await db.collection('guides')
            .orderBy('createdAt', 'desc')
            .limit(100).get()
      } else {
        querySnap = await db.collection('guides')
            .where('user.uid', '==', auth.currentUser.uid)
            .orderBy('createdAt', 'desc')
            .limit(100).get()
      }
      querySnap.forEach((doc) => {
        let obj = {
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt.toDate(),
          accepted: doc.data().accepted || false
        }
        if (obj.quote) {
          obj.quote = {
            ...doc.data().quote,
            createdAt: doc.data().quote.createdAt.toDate()
          }
        }
        this.list.push(obj)
      })
    },
    /**
     * Get providers of firestore
     * @returns {Promise<void>}
     */
    async loadProviders () {
      this.clients = []
      const querySnap = await db.collection('providers').where('state', '==', true).orderBy('businessName', 'asc').get()
      querySnap.forEach((doc) => {
        let client = {
          id: doc.id,
          document: doc.data().document,
          typeDocument: doc.data().typeDocument,
          businessName: doc.data().businessName,
          label: doc.data().businessName + ' - ' + doc.data().document,
          address: doc.data().address,
          department: doc.data().department,
          province: doc.data().province,
          district: doc.data().district,
          ubigeo: doc.data().ubigeo
        }
        this.providers.push(client)
      })
    },
    /**
     * Get quotes of firestore
     * @returns {Promise<void>}
     */
    async loadQuotes () {
      this.quotes = []
      let querySnap = {}
      if (this.$acl.check('admin')) {
        querySnap = await db.collection('quotes')
            .where('state', '==', true)
            .where('status', '==', 'inProcess')
            .orderBy('createdAt', 'desc')
            .limit(100).get()
      } else {
        querySnap = await db.collection('quotes')
            .where('user.uid', '==', auth.currentUser.uid)
            .where('state', '==', true)
            .where('status', '==', 'inProcess')
            .orderBy('createdAt', 'desc')
            .limit(100).get()
      }
      querySnap.forEach((doc) => {
        let quote = {
          id: doc.id,
          code: doc.data().code,
          client: doc.data().client,
          contact: doc.data().contact,
          currency: doc.data().currency,
          total: doc.data().total,
          igv: doc.data().igv,
          subTotal: doc.data().subTotal,
          products: doc.data().products,
          createdAt: doc.data().createdAt.toDate(),
          roundingType: doc.data().roundingType,
          status: doc.data().status,
          link: doc.data().link
        }
        this.quotes.push(quote)
      })
    },
    /**
     * Get vehicles of firestore
     */
    async loadVehicles () {
      const querySnapshot = await db.collection('vehicles').where('state', '==', true).orderBy('createdAt', 'asc').get()
      querySnapshot.forEach((doc) => {
        let vehicle = {
          id: doc.id,
          ...doc.data()
        }
        this.vehicles.push(vehicle)
      })
    },
    /**
     * Get clients of firestore
     * @returns {Promise<void>}
     */
    async loadClients () {
      this.clients = []
      const querySnap = await db.collection('clients').where('state', '==', true).orderBy('businessName', 'asc').get()
      querySnap.forEach((doc) => {
        let client = {
          id: doc.id,
          document: doc.data().document,
          typeDocument: doc.data().typeDocument,
          businessName: doc.data().businessName,
          label: doc.data().businessName + ' - ' + doc.data().document,
          address: doc.data().address,
          department: doc.data().department,
          province: doc.data().province,
          district: doc.data().district,
          ubigeo: doc.data().ubigeo,
          email: doc.data().email
        }
        this.clients.push(client)
      })
    },
    /**
     * Get products of algolia
     * @returns {Promise<void>}
     */
    async loadProducts () {
      // Search products in algolia, if search is empty list preview
      let response = await index.search('', {
        hitsPerPage: 20,
      })
      response.hits.forEach((p) => {
        this.products.push({
          description: p.description,
          sku: p.objectID.substr(0, 6).toUpperCase(),
          numberPart: p.numberPart,
          cost: p.price,
          id: p.objectID
        })
      })
      // End
    },
    /**
     * Reset filters
     * @returns {Promise<void>}
     */
    async resetFilters () {
      this.initProgress = true
      this.list = []
      this.startDate = null
      this.endDate = null
      this.client = null
      await this.loadGuides()
      this.$refs.filterCard.removeRefreshAnimation()
      this.initProgress = false
    },
    /**
     * Filter by date
     */
    async filter () {
      try {
        if (this.purchaseOrderFilter) {
          this.initProgress = true
          this.list = []
          let querySnapshot = {}
          querySnapshot = await db.collection('guides')
              .where('purchaseOrder', '==', this.purchaseOrderFilter).get()

          querySnapshot.forEach((doc) => {
            let obj = {
              id: doc.id,
              ...doc.data(),
              createdAt: doc.data().createdAt.toDate()
            }
            this.list.push(obj)
          })
        }
        else {
          if (this.startDate && this.endDate) {
            this.initProgress = true
            this.list = []
            // Set start date
            this.startDate.setHours(0)
            this.startDate.setMinutes(0)
            // Set end date
            this.endDate.setHours(23)
            this.endDate.setMinutes(59)

            const starTimes = Timestamp.fromDate(this.startDate)
            const endTimes = Timestamp.fromDate(this.endDate)
            let querySnapshot = {}

            if (this.client) {
              if (this.$acl.check('admin')) {
                querySnapshot = await db.collection('guides')
                    .where('createdAt', '>=', starTimes)
                    .where('createdAt', '<=', endTimes)
                    .where('client.id', '==', this.client.id).orderBy('createdAt', 'desc').get()
              }
              else {
                querySnapshot = await db.collection('guides')
                    .where('user.uid', '==', auth.currentUser.uid)
                    .where('createdAt', '>=', starTimes)
                    .where('createdAt', '<=', endTimes)
                    .where('client.id', '==', this.client.id).orderBy('createdAt', 'desc').get()
              }
            }
            else {
              if (this.$acl.check('admin')) {
                querySnapshot = await db.collection('guides')
                    .where('createdAt', '>=', starTimes)
                    .where('createdAt', '<=', endTimes).orderBy('createdAt', 'desc').get()
              }
              else {
                querySnapshot = await db.collection('guides')
                    .where('user.uid', '==', auth.currentUser.uid)
                    .where('createdAt', '>=', starTimes)
                    .where('createdAt', '<=', endTimes).orderBy('createdAt', 'desc').get()
              }
            }
            querySnapshot.forEach((doc) => {
              let obj = {
                id: doc.id,
                ...doc.data(),
                createdAt: doc.data().createdAt.toDate()
              }
              this.list.push(obj)
            })
          }
        }
      } catch (e) {
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.initProgress = false
      }
    },
    /**
     * Send to Eprint
     * @param tr
     */
    openPrintDialog (tr) {
      this.selectedGuide = tr
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmación',
        text: `¿Deseas imprimir remotamente la guía "${tr.code}"?`,
        accept: this.acceptDialogPrint,
        cancel: '',
        parameters: [tr],
        acceptText: 'Imprimir',
        cancelText: 'Cancelar'
      })
    },
    /**
     * Eprint
     * @returns {Promise<void>}
     */
    async acceptDialogPrint () {
      try {
        this.initProgress = true
        await axios.post('https://southamerica-east1-anglidata-1aa70.cloudfunctions.net/print-sendToPrint', {
          url: this.selectedGuide.nubefact.enlace_del_pdf
        }, {
          headers: {
            Authorization: 'Bearer bd14b377-92f6-4741-8303-1f372131897c'
          }
        })

        this.selectedGuide = {}
        this.$vs.notify({
          color: 'success',
          title: 'Guía de remisión',
          text: 'Guía de remisión enviada a impresión.'
        })
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: e.response.data.errors
        })
      } finally {
        this.initProgress = false
      }
    },
    /**
     * Low communication invoice confirm
     * @param tr
     */
    openCancelDialog (tr) {
      this.selectedGuide = tr
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmación',
        text: `¿Deseas anular la guía "${tr.code}"?`,
        accept: this.acceptDialogLowCommunication,
        cancel: '',
        parameters: [tr],
        acceptText: 'Anular',
        cancelText: 'Cancelar'
      })
    },
    /**
     * Verify guide
     * @param tr
     */
    async verifyGuide (tr) {
      try {
        if (!tr.accepted) {
          this.selectedGuide = tr

          this.initProgress = true
          const response = await axios.post(' https://southamerica-east1-anglidata-1aa70.cloudfunctions.net/nubefact-verifyGuide', {
            document: {
              'operacion': 'consultar_guia',
              'tipo_de_comprobante': 7,
              'serie': tr.serie,
              'numero': tr.sequence
            }
          }, {
            headers: {
              Authorization: 'Bearer bd14b377-92f6-4741-8303-1f372131897c'
            }
          })

          /// Update in firestore
          await db.collection('guides').doc(tr.id).update({
            nubefact: {
              ...response.data.nubefact
            },
            accepted: response.data.nubefact.aceptada_por_sunat
          })
          // End

          if (response.data.nubefact.aceptada_por_sunat) {
            // Open PDF in browser
            window.open(response.data.nubefact.enlace_del_pdf, 'blank')
            // End
            // Update row
            this.selectedGuide.nubefact = {
              ...response.data.nubefact
            }
            this.selectedGuide.accepted = true
            // End

            this.$vs.notify({
              color: 'success',
              title: 'Guía de remisión',
              text: 'Guía aceptada por Sunat',
              time: 5000
            })
          } else {
            this.$vs.notify({
              color: 'warning',
              title: 'Guía de remisión',
              text: 'Guía no aceptada por Sunat',
              time: 5000
            })
          }
        }
      } catch (e) {
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: e.response.data.errors,
        })
      } finally {
        this.initProgress = false
      }
    },
    /**
     * Low communication send nubefact
     * @returns {Promise<void>}
     */
    async acceptDialogLowCommunication () {
      try {
        this.initProgress = true
        // Update status and response to nubefact
        await db.collection('guides').doc(this.selectedGuide.id).update({
          status: 'rejected'
        })
        const objInvoice = this.list.find((o) => o.id === this.selectedGuide.id)
        objInvoice.status = 'rejected'

        // Delete products to quote
        const quoteDoc = await db.collection('quotes').doc(this.selectedGuide.quote.id).get()
        const quoteProducts = [...quoteDoc.data().products]
        quoteProducts.forEach((qp, index) => {
          const product = this.selectedGuide.products.find((p) => p.id === qp.id)
          quoteProducts[index].guide -= product.quantity
        })
        // Update Quote status and products
        await db.collection('quotes').doc(this.selectedGuide.quote.id).update({
          products: quoteProducts,
          updatedAt: FieldValue.serverTimestamp()
        })
        /* End */

        this.selectedGuide = {}
        this.$vs.notify({
          color: 'success',
          title: 'Guía de remisión',
          text: 'Guía anulada correctamente.'
        })
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: e.response.data.errors
        })
      } finally {
        this.initProgress = false
      }
    },
    /**
     * Listen keyboard
     */
    listenKeyboard (ev) {
      if (this.selected.id && !this.showNewSidebar && !this.showNewSidebarWC && !this.activePrompt) {
        if (ev.keyCode === 79) { // Open Link (O)
          this.openLink(this.selected.nubefact.enlace_del_pdf)
        } else if (ev.keyCode === 73) { // Print (I)
          this.openPrintDialog(this.selected)
        } else if (ev.keyCode === 82) { // Resend (I)
          if (this.selected.status === 'hold') {
            this.openResendDialog(this.selected)
          }
        }
      }
    },
    /**
     * Resend invoice to nubefact confirm
     * @param tr
     */
    openResendDialog (tr) {
      this.selectedGuide = tr
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmación',
        text: `¿Deseas enviar a Nubefact la guía "${tr.code}"?`,
        accept: this.sendNubefact,
        cancel: '',
        parameters: [tr],
        acceptText: 'Enviar',
        cancelText: 'Cancelar'
      })
    },
    /**
     * Seng guide tu nubefact
     */
    async sendNubefact () {
      try {
        this.initProgress = true
        const sequence = this.selectedGuide.sequence
        const items = []
        this.selectedGuide.products.forEach((p) => {
          const product = _.cloneDeep(p)
          if (product.serials) {
            product.description += `\n NS: ${product.serials.join(', ')}`
          }
          const obj = {
            'unidad_de_medida': 'NIU',
            'codigo': product.numberPart.toUpperCase(),
            'descripcion': product.description,
            'cantidad': product.quantity
          }
          items.push(obj)
        })
        // Split emails

        // Get client
        const clientDoc = await db.collection('clients').doc(this.selectedGuide.client.id).get()
        const emails = clientDoc.data().email !== '_' ? clientDoc.data().email.split(',') : []
        // End

        // Document for Nubefact
        let document = {
          'operacion': 'generar_guia',
          'tipo_de_comprobante': 7,
          'serie': 'TTT1',
          'numero': sequence,
          'cliente_tipo_de_documento': this.selectedGuide.client.typeDocument === 'RUC' ? 6 : 1,
          'cliente_numero_de_documento': this.selectedGuide.client.document,
          'cliente_denominacion': clientDoc.data().businessName,
          'cliente_direccion': clientDoc.data().address,
          // 'cliente_email': emails.length === 1 ? emails[0].trim() : '',
          'cliente_email': 'ppuerta@anglidata.com',
          'cliente_email_1': emails.length === 2 ? emails[1].trim() : '',
          'cliente_email_2': emails.length === 3 ? emails[2].trim() : '',
          'fecha_de_emision': new Date().getDate() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getFullYear(),
          'observaciones': this.selectedGuide.purchaseOrder ? ('OC. Cliente: ' + this.selectedGuide.purchaseOrder) : '',
          'motivo_de_traslado': this.selectedGuide.type === 'regular' ? '01' : this.selectedGuide.motive.id,
          'peso_bruto_total': '1',
          'numero_de_bultos': '0',
          'tipo_de_transporte': this.selectedGuide.typeOfTransport.id,
          'fecha_de_inicio_de_traslado': new Date().getDate() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getFullYear(),

          'transportista_documento_tipo': 6, // 7 if RUc
          'transportista_documento_numero': this.selectedGuide.typeOfTransport.id === '02' ? '20341031517' : this.selectedGuide.documentOfCarrier,
          'transportista_denominacion': this.selectedGuide.typeOfTransport.id === '02' ? 'AngliData Express EIRL' : this.selectedGuide.carrier,
          'transportista_placa_numero': 'C6K-758',

          'punto_de_partida_ubigeo': '150141',
          'punto_de_partida_direccion': 'CAL. PEREZ ARANIBAR NRO. 134 INT. 303',
          'punto_de_llegada_ubigeo': this.selectedGuide.address.ubigeo + "",
          'punto_de_llegada_direccion': this.selectedGuide.address.address,
          'enviar_automaticamente_a_la_sunat': true,
          'enviar_automaticamente_al_cliente': true,
          'codigo_unico': '',
          'formato_de_pdf': ''
        }
        // End

        if (this.selectedGuide.typeOfTransport.id === '02') {
          document = {
            ...document,
            //
            'conductor_documento_tipo': 1,
            'conductor_documento_numero': this.selectedGuide.vehicle.driverDocument,
            'conductor_denominacion': this.selectedGuide.vehicle.driverName,

            'conductor_numero_licencia': 'Q07263813',
            'conductor_nombre': 'Pedro',
            'conductor_apellidos': 'Puerta',
            //
          }
        }

        // Send to nubefact
        const response = await this.$http.post('https://southamerica-east1-anglidata-1aa70.cloudfunctions.net/nubefact-sendReferenceGuides', {
          document,
          items
        }, {
          headers: {
            'Authorization': 'Bearer bd14b377-92f6-4741-8303-1f372131897c'
          }
        })
        // End

        // Update in firestore
        await db.collection('guides').doc(this.selectedGuide.id).update({
          nubefact: {
            ...response.data.nubefact
          },
          status: 'send',
          accepted: false
        })
        // End

        this.selectedGuide.status = 'send'
        this.selectedGuide.nubefact = {
          ...response.data.nubefact
        }
        this.$vs.notify({
          color: 'success',
          title: 'Guía de remisión',
          text: 'Guía de remisión enviada correctamente.'
        })
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: e.response.data.errors,
          time: 7000
        })
      } finally {
        this.initProgress = false
      }
    }
  }
}
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

        td {
          padding: 10px;

          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }

          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}

.product-name {
  max-width: 23rem;
}
</style>
